<script>
export default {
  name: "WhyEOffice",
};
</script>

<template>
  <div class="container">
    <h2 class="mb-3 mb-lg-5 h3-responsive font-weight-normal text-center">
      Neden eOfis?
    </h2>
    <div
      class="row text-start features-small mb-5 mt-3 wow fadeIn"
      style="visibility: visible; animation-name: fadeIn"
    >
      <div class="col-12 col-md-4">
        <div class="row my-2 ml-2 ml-lg-0">
          <div class="col-2 d-flex justify-content-center align-items-center">
            <i class="bi bi-check-circle-fill fs-3 text-success"></i>
          </div>
          <div class="col-10 d-flex justify-content-left align-items-center">
            <div class="feature-title my-0">
              Sektördeki En Fazla Lokasyon Sayısı
            </div>
          </div>
        </div>
        <div class="row my-2 ml-2 ml-lg-0">
          <div class="col-2 d-flex justify-content-center align-items-center">
            <i class="bi bi-check-circle-fill fs-3 text-success"></i>
          </div>
          <div class="col-10 d-flex justify-content-left align-items-center">
            <div class="feature-title my-0">Sektörde 8 Yıllık Tecrübe</div>
          </div>
        </div>
        <div class="row my-2 ml-2 ml-lg-0">
          <div class="col-2 d-flex justify-content-center align-items-center">
            <i class="bi bi-check-circle-fill fs-3 text-success"></i>
          </div>
          <div class="col-10 d-flex justify-content-left align-items-center">
            <div class="feature-title my-0">Sadece A+ Prestijli Plazalar</div>
          </div>
        </div>
        <div class="row my-2 ml-2 ml-lg-0">
          <div class="col-2 d-flex justify-content-center align-items-center">
            <i class="bi bi-check-circle-fill fs-3 text-success"></i>
          </div>
          <div class="col-10 d-flex justify-content-left align-items-center">
            <div class="feature-title my-0">Sürekli Artan Lokasyon Ağı</div>
          </div>
        </div>
        <div class="row my-2 ml-2 ml-lg-0">
          <div class="col-2 d-flex justify-content-center align-items-center">
            <i class="bi bi-check-circle-fill fs-3 text-success"></i>
          </div>
          <div class="col-10 d-flex justify-content-left align-items-center">
            <div class="feature-title my-0">
              Tek Sözleşmeyle Tüm Lokasyonları Kullanma İmkanı
            </div>
          </div>
        </div>
        <div class="row my-2 ml-2 ml-lg-0">
          <div class="col-2 d-flex justify-content-center align-items-center">
            <i class="bi bi-check-circle-fill fs-3 text-success"></i>
          </div>
          <div class="col-10 d-flex justify-content-left align-items-center">
            <div class="feature-title my-0">
              10000+ Firmalı Güçlü Network Ağı
            </div>
          </div>
        </div>
        <div class="row my-2 ml-2 ml-lg-0">
          <div class="col-2 d-flex justify-content-center align-items-center">
            <i class="bi bi-check-circle-fill fs-3 text-success"></i>
          </div>
          <div class="col-10 d-flex justify-content-left align-items-center">
            <div class="feature-title my-0">Uluslararası Ofis Ağı</div>
          </div>
        </div>
        <div class="row mt-2 ml-2 ml-lg-0">
          <div class="col-2 d-flex justify-content-center align-items-center">
            <i class="bi bi-check-circle-fill fs-3 text-success"></i>
          </div>
          <div class="col-10 d-flex justify-content-left align-items-center">
            <div class="feature-title my-0">A+ Plazalarda En İyi Fiyat</div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-4 flex-center d-none d-md-flex">
        <img
          src="//www.eofis.com.tr/ass/img/office-x.webp"
          alt="eOfis"
          class="z-depth-0 img-fluid"
        />
      </div>

      <div class="col-12 col-md-4">
        <div class="row my-2 ml-2 ml-lg-0">
          <div class="col-2 d-flex justify-content-center align-items-center">
            <i class="bi bi-check-circle-fill fs-3 text-success"></i>
          </div>
          <div class="col-10 d-flex justify-content-left align-items-center">
            <div class="feature-title my-0">Sürpriz Ücret Yok</div>
          </div>
        </div>
        <div class="row my-2 ml-2 ml-lg-0">
          <div class="col-2 d-flex justify-content-center align-items-center">
            <i class="bi bi-check-circle-fill fs-3 text-success"></i>
          </div>
          <div class="col-10 d-flex justify-content-left align-items-center">
            <div class="feature-title my-0">
              Metro ve Metrobüs ile Kolay Ulaşım
            </div>
          </div>
        </div>
        <div class="row my-2 ml-2 ml-lg-0">
          <div class="col-2 d-flex justify-content-center align-items-center">
            <i class="bi bi-check-circle-fill fs-3 text-success"></i>
          </div>
          <div class="col-10 d-flex justify-content-left align-items-center">
            <div class="feature-title my-0">
              Mobil Uygulama ile Ofis Yönetimi
            </div>
          </div>
        </div>
        <div class="row my-2 ml-2 ml-lg-0">
          <div class="col-2 d-flex justify-content-center align-items-center">
            <i class="bi bi-check-circle-fill fs-3 text-success"></i>
          </div>
          <div class="col-10 d-flex justify-content-left align-items-center">
            <div class="feature-title my-0">Gelişmiş Çağrı Sistemi</div>
          </div>
        </div>
        <div class="row my-2 ml-2 ml-lg-0">
          <div class="col-2 d-flex justify-content-center align-items-center">
            <i class="bi bi-check-circle-fill fs-3 text-success"></i>
          </div>
          <div class="col-10 d-flex justify-content-left align-items-center">
            <div class="feature-title my-0">Şeffaf Yönetim</div>
          </div>
        </div>
        <div class="row my-2 ml-2 ml-lg-0">
          <div class="col-2 d-flex justify-content-center align-items-center">
            <i class="bi bi-check-circle-fill fs-3 text-success"></i>
          </div>
          <div class="col-10 d-flex justify-content-left align-items-center">
            <div class="feature-title my-0">
              120 Adet Toplantı ve Seminer Salonu
            </div>
          </div>
        </div>
        <div class="row my-2 ml-2 ml-lg-0">
          <div class="col-2 d-flex justify-content-center align-items-center">
            <i class="bi bi-check-circle-fill fs-3 text-success"></i>
          </div>
          <div class="col-10 d-flex justify-content-left align-items-center">
            <div class="feature-title my-0">Lansman Alanları</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
