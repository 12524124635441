<script>
export default {
  name: "GreyInfoCard",
};
</script>

<template>
  <div class="bg-light bg-opacity-75 text-center rounded">
    <h5 class="fs-6">58</h5>
    <p class="fs-6">A+ Plaza</p>
  </div>
</template>

<style scoped lang="scss">
h5,
p {
  margin-bottom: 0;
}
</style>
