<script>
export default {
  name: "WelcomePage",
};
</script>

<template>
  <div
    class="d-flex h-100 text-center text-bg-dark cover-container d-flex w-100 h-100 p-3 mx-auto flex-column"
    style="height: 100vh !important"
  >
    <header class="mb-auto">
      <div>
        <h3 class="float-md-start mb-0"></h3>
      </div>
    </header>

    <main class="px-3">
      <h1>Akdeniz Business Center</h1>
      <p class="lead">Çok Yakında Hizmetinizde...</p>
      <!--      <p class="lead">-->
      <!--        <a href="#" class="btn btn-lg btn-light fw-bold border-white bg-white"-->
      <!--          >Learn more</a-->
      <!--        >-->
      <!--      </p>-->
    </main>

    <footer class="mt-auto text-white-50">
      <!--      <p>-->
      <!--        Cover template for-->
      <!--        <a href="https://getbootstrap.com/" class="text-white">Bootstrap</a>, by-->
      <!--        <a href="https://twitter.com/mdo" class="text-white">@mdo</a>.-->
      <!--      </p>-->
    </footer>
  </div>
</template>

<style scoped lang="scss"></style>
